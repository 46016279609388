
/* .video-background {
    position: relative; 
    z-index: -1;
    width: 100%;
    height: auto;
}

.video-background video {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

/* .main {
    width: 100%;
    height: 100vh;
  } */
  
  /* video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  
  /* .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  } */
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,.4); */
    /* background-color: #000000cc; */
  }
.video-background {
  position: relative;
  width: 100%;
  height: 100vh; /* Ajusta la altura según tus necesidades */
  }
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .text-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .text-overlay p {
    font-size: 1.5rem;
  }
  
  .gif-background {
    position: relative;
    width: 100%;
    height: 100vh; /* Ajusta la altura según tus necesidades */
  }
  
  .background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
/* Contact.css */

.centered-contact {
    height: 100vh; /* Ajusta la altura según tus necesidades */
  }
  
  .centered-contact .visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .contact {
    margin-top: -80px;
    padding: 0;
    position: sticky;
  }
  
  .contact form {
    margin: 0;
    padding: 0;
  }

  .custom-button {
    font-size: 14px;
    padding: 8px 16px;
    width: 100%;
    max-width: 200px;
    text-align: center;
    border-radius: 20px; /* Agrega borde redondeado */
    border: none; /* Elimina el borde predeterminado */
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    transition: background-color 0.3s, color 0.3s; /* Transiciones suaves */
  }

/************ Footer ************/
.footer {
    margin-top: 9rem;
    padding: 10px 0 20px 0;
    background-color: #515153;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .newsletter-bx {
    background: #FFFFFF;
    border: 2px solid #f7934a;
    border-radius: 55px;
    color: #121212;
    padding: 1rem 1rem;
    margin-bottom: 20px;
    margin-top: -5rem;
    display: flex; /* Agrega esto */
    flex-direction: column; /* Agrega esto */
    align-items: center; /* Agrega esto */
    text-align: center; /* Alinea el texto al centro horizontalmente */
  }
  
  .newsletter-bx h3 {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
    margin-top: 2rem;
  }
  
  .newsletter-bx img{
    width: 50% !important;
    margin: 0 auto;
    position: relative;
  }
  
  .new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
  }
  .new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
  }
  .new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .new-email-bx input {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
  }
  .new-email-bx button {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 20px 65px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 18px;
  }
  .footer img {
    width: 26%;
  }
  .footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  
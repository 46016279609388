/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}



.black-background {
  background-color: #000000;
  padding: 50px 0; /* Agrega relleno para separar el contenido del fondo */
}

.animated-card.hovered {
  background-color: #f7934a;
}

.animated-card.hovered {
  background-color: #f7934a;
  transform: scale(0.98); /* Escala ligeramente aumentada en hover */
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: #fff;
  font-size: 14px; /* Ajusta el tamaño del texto */
}

/* Estilos para la información adicional */
.additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: #fff;
}

.card-carousel {
  padding: 20px 0;
}

.carousel-icon {
  font-size: 24px;
  cursor: pointer;
}

.card-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  gap: 10px;
}

.animated-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.animated-card.active {
  transform: scale(1.1); /* Tamaño ligeramente aumentado para la tarjeta activa */
}

/* Estilos para el carrusel */
.card-carousel {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

.animated-card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.additional-info {
  background-color: #f9d165;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}


/* Estilos para el carrusel y las flechas */
.card-carousel {
  position: relative;
  padding: 20px; /* Agregar un poco de espacio alrededor del carrusel */
}

.slick-slider {
  overflow: visible; /* Hacer que las tarjetas se extiendan fuera del carrusel */
  margin: 0 -10px; /* Agregar margen negativo para el espacio horizontal entre tarjetas */
}

.slick-slide {
  padding: 0 10px; /* Agregar espacio horizontal entre las tarjetas */
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: -30px; /* Colocar la flecha a la izquierda del carrusel */
}

.next {
  right: -30px; /* Colocar la flecha a la derecha del carrusel */
}

.infinite-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.infinite-carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  align-items: center;
}

.card {
  flex: 0 0 auto;
  margin: 0 10px; /* Espacio entre las tarjetas */
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}


/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

/* a:hover {
  text-decoration: none;
} */

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

/* @media (min-width: 1200px){
  main .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1228px;
  }
} */
p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  /* padding: 18px 0;
  
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  background-color: #1212128f; */
  background-color: #515153;
  width: 100%;
  margin: auto;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition:  0.32s ease-in-out; 
}
/* nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #1212128f;
} */
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  /* font-weight: 400;
  color: #ffffff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75; */
  list-style: none;
  color: #ffff !important;    
  letter-spacing: 0.8px;
  /* display: inline-block; */
  margin: 0 20px;
  position: relative;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:before {
  content: "";
  height: 3px;
  width: 0%;
  background: #f7934a;
  position: absolute;
  left: 0;
  bottom: -12px;
  transition: 0.4s ease-out;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover::before,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  width: 100%;
}

span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: -0.5rem;
  /* margin-bottom: 10rem; */
  padding: 150px 0 100px 0;
  /* padding: 100px 0 100px 0; */
  /* background-image: url("https://img.mit.edu/files/images/202211/MIT-Neural-Networks-SL.gif"); */
  /* background-image: url("https://d2z8nyy70yf33i.cloudfront.net/wp-content/uploads/02_gif.gif"); */
  /* background-image: url('./assets/img/fondo.png'); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .title-banner{
  /* position: relative;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); */
  z-index: 500; /* Asegura que el título esté en la parte superior */
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 8px;
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); */
  /* background: linear-gradient(90deg, rgba(35,50,7,1) 0%, rgba(9,121,35,1) 42%, rgba(0,255,209,1) 100%); */
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  font-size: 20px;
  /* margin-bottom: 16px; */
  display: inline-block;
}
.banner h1 {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  /* margin-bottom: 20px; */
  display: block;
}
.banner p {
  /* color: #B8B8B8; */
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  position: relative;
  /* animation: updown 3s linear infinite; */
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  /* border-right: 0.08em solid #f7934a; */
  font-style: italic;
  color: #534d4d;
}

/************ teams Css ************/
.team {
  padding: 60px 0 5px 0;
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;
}
.team-bx {
  /* background: #151515; */
  background: #14CA7E;
  border-color: #00ffd1;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.team h2 {
	font-size: 45px;
	font-weight: 700;
}
.team p {
    /* color: #B8B8B8; */
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.team-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.team-slider .item img {
  /* width: 50%; */
  margin: 0 auto 15px auto;
}
.background-image-left {
  /* top: 28%; */
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  /* padding: 80px 0; */
  padding: 20px 0;
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.project h1 {
	font-size: 36px;
	font-weight: 700;
  text-align: center;
  color: #f7934a;
}
.project p {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .row-cards{
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  /* width: 33.33333%; */
  width: 25%
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
    background: linear-gradient(90deg, rgba(35,50,7,1) 0%, rgba(9,121,35,1) 42%, rgba(0,255,209,1) 100%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-fourth {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  /* background: linear-gradient(90deg, rgba(35,50,7,1) 0%, rgba(9,121,35,1) 42%, rgba(0,255,209,1) 100%); */
  background: rgba(247,147,74,1);
/* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(247,147,74,1) 46%, rgba(170,99,48,1) 100%); */
  /* background: linear-gradient(0deg, rgba(39,159,17,1) 0%, rgba(45,253,205,1) 100%); */
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  color: #fff;
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  /* top: 20%; */
  top: 0%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  /* z-index: -4; */
}

/************ contact Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  /* background: linear-gradient(0deg,  rgba(45,253,205,1)  0%, rgba(39,159,17,1) 100%); */
  /* background: linear-gradient(0deg, rgba(23,223,163,1) 0%, rgba(99,173,228,1) 100%); */
  /* background: linear-gradient(0deg, rgba(23,223,163,1) 0%, rgba(12,10,10,1) 100%); Me gusta este */
  /* background: linear-gradient(0deg, rgba(23,223,163,1) 18%, rgba(0,0,0,1) 100%); */
  /* padding: 60px 0 200px 0; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #f7934a;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #f7934a;
  border-radius: 20px;
  color: #515153;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: #eee9e9;
  color: #515153;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #515153;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.button-modal{
  font-weight: 500;
  padding: 5px 5px !important;
  font-size: 14px !important;
  position: relative;
  transition: 0.3s ease-in-out;
}

.button-modal:hover{
  font-weight: 500;
  background-color: #f7934a !important;
  padding: 5px 5px !important;
  font-size: 14px !important;
  position: relative;
}

.contact form button {
  font-weight: 700;
  color: #fafafa;
  background-color: #515153;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 9px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #272626;
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}


/********* imagen **********/

.box{
  width: 180px;
  height: 180px;
  /* background-image: url('./assets/img/pipe.jpeg'); */
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto;
}

.boxCard{
  width: 500px;
  height: 230px;
  /* background-image: url('./assets/img/pipe.jpeg'); */
  /* background-size: cover; */
  /* border-radius: 50%; */
  margin: 0 auto;
}

/********* testimonial **********/
.testimonial{
  margin-top: 5rem;
  margin-bottom: 5rem;
  /* position: relative; */
  background: url('../assets/img/Quienes_somos.jpg');

  background-position: center;
  background-size: 90%;
  background-size: cover;
  background-attachment: fixed;
  padding: 80px 0;
}

.testimonial-content {
  /* padding: 30px; */
  text-align: center;
  background: #fff;
  padding: 3rem 5rem 3rem;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

/********* infoCards **********/
.service-item {
  /* height: 320.156px; */
  height: 400.200px;
  background: #fff;
  padding: 50px 30px 50px;
  text-align: center;
  border: 0px solid #0000001c;
  border-radius: 6px;
  margin-bottom: 30px;
  transform: scale(1); 
  transition: all .3s ease-in-out;
}
.service-item h3 {
  text-transform: capitalize;
  line-height: 26px;
}
.service-item p {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  display: block;
  width: 100%  !important; 
}

.service-item:hover{
  transform: scale(1.1); /* Escala al 110% del tamaño original al pasar el cursor por encima */

}

.ti-help-alt{
  font-size: 30px;
}

.service-item:hover .ti-help-alt {
  /* Cambia el color del ícono cuando el mouse está sobre el componente */
  color: #f7934a; /* Color del ícono al pasar el cursor por encima del componente */
}

.service-item i {
  font-size: 40px;
  margin-bottom: 40px;
  margin-top: -2rem;
  color: #252525;
  display: inline-block;
  transition: all .3s ease-in-out;
}
